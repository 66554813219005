import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

const SEO = props => {
  const { site, profile } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            locale
            title
          }
        }
        profile: profileYaml {
          name
          profession
          about
        }
      }
    `
  )

  const {
    description = profile.about.replace(/(\r\n|\n|\r|-)/gm, " ").replace(/\s+/g, " "), // site.siteMetadata.description,
    meta = [],
    title = profile.name.replace("F.", "Florian") + " | " + profile.profession
  } = props

  return (
    // <Helmet
    //   htmlAttributes={{
    //     lang: site.siteMetadata.locale,
    //   }}
    //   title={title}
    //   meta={[
    //     {
    //       name: `description`,
    //       content: description,
    //     },
    //     {
    //       property: `og:title`,
    //       content: title,
    //     },
    //     {
    //       property: `og:description`,
    //       content: description,
    //     },
    //     {
    //       property: `og:type`,
    //       content: `website`,
    //     },
    //     {
    //       name: `twitter:card`,
    //       content: `summary`,
    //     },
    //     {
    //       name: `twitter:title`,
    //       content: title,
    //     },
    //     {
    //       name: `twitter:description`,
    //       content: description,
    //     },
    //     {
    //       name: `robots`,
    //       content: `index, follow`,
    //     }
    //   ].concat(meta)}
    // >
    // https://github.com/nfl/react-helmet
    <Helmet>
      <html lang={site.siteMetadata.locale} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content="index, follow, noarchive" />
      <link rel="canonical" href="https://ianfs.com/" />
      <link rel="alternate" hreflang="de" href="https://scheel.eu/" />
      <link rel="shortcut icon" href="/favicon.ico" />
      <meta property="og:site_name" content={profile.name} />
    </Helmet>
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
